import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import mylocale from './my-locale'
import categories from './categories'
import sliders from './sliders'
import cities from './cities'
import tags from './tags'
import getUser from './users/getUser'
import getMerchant from './merchants/getMerchant'
import getBranch from './branches/getBranch'
import getOffer from './offers/getOffer'
import getProduct from './products/getProduct'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    mylocale,
    categories,
    sliders,
    tags,
    cities,
    getUser,
    getMerchant,
    getBranch,
    getOffer,
    getProduct,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
