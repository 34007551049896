import axiosIns from "@/libs/axios.js";
import { LogOutIcon } from "vue-feather-icons";

export default {
  state: {
    user: [],
  },
  getters: {
    getUser: (state) => state.user,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async fetchUser(ctx, id) {
      const response = await axiosIns.get(`v1/user/${id}`);
      ctx.commit("setUser", response.data.data);
    },
  },
};
