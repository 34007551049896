import axiosIns from "@/libs/axios.js";

export default {
  state: {
    sliders: [],
    slider: {},
  },
  getters: {
    getSliders: (state) => state.sliders,
    getSlider: (state) => state.slider,
  },
  mutations: {
    setSliders(state, sliders) {
      state.sliders = sliders;
    },
    setSlider(state, slider) {
      state.slider = slider;
    }
  },
  actions: {
    async fetchSliders(ctx) {
      const response = await axiosIns.get("v1/slider/getAll", {
        params: {
          page: 1,
          size: 50
        }
      });
      ctx.commit("setSliders", response.data.data.sliderList);
    },
    async fetchSlider(ctx, id) {
      const response = await axiosIns.get(`v1/slider/get-by-id/${id}`);
      ctx.commit("setSlider", response.data.data);
    }
  }
};
