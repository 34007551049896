import axiosIns from "@/libs/axios.js";

export default {
  state: {
    offer: [],
    offerImages: [],
  },
  getters: {
    getOffer: (state) => state.offer,
    getOfferImages: (state) => state.offerImages,
  },
  mutations: {
    setOffer(state, offer) {
      state.offer = offer;
    },
    setOfferImages(state, offerImages) {
      const filteredOfferImagesForSubmit = state.offer.offerImageList.map(
        function (item) {
          return item.url
        }
      );
      state.offer.offerImageList =
        filteredOfferImagesForSubmit.concat(offerImages);
    },
  },
  actions: {
    async fetchOffer(ctx, id) {
      const response = await axiosIns.get(`offer/${id}`);

      const filteredOfferImages = response.data.data.offerImageList.map(
        function (item) {
          return {
            id: item.id,
            name: "",
            size: 0,
            type: "image",
            url: item.url,
            myurl: item.url,
          };
        }
      );

      response.data.data.filteredOfferImages = filteredOfferImages;
      ctx.commit("setOffer", response.data.data);
    },
  },
};
