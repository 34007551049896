import axiosIns from "@/libs/axios.js";

export default {
  state: {
    tags: []
  },
  getters: {
    getTags: (state) => state.tags
  },
  mutations: {
    setTags(state, tags) {
      state.tags = tags;
    }
  },
  actions: {
    async fetchTags(ctx) {
      const response = await axiosIns.get("business-type/getAll");
      ctx.commit("setTags", response.data.data);
    }
  }
};
