import Vue from "vue";
import i18n from '@/libs/i18n'
// axios
import axios from "axios";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: "http://82.148.4.77:8088/api/",
  headers: {
    "device-id": "2309gjslds923fslf9w",
    "app-version": "Av1.0.15 (debug 16)",
    "lang": `${i18n.locale}`
  }
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
