import axiosIns from "@/libs/axios.js";

export default {
  state: {
    merchant: []
  },
  getters: {
    getMerchant: (state) => state.merchant
  },
  mutations: {
    setMerchant(state, merchant) {
      state.merchant = merchant;
    }
  },
  actions: {
    async fetchMerchant(ctx, id) {
      const response = await axiosIns.get(`vendor/${id}`);
      ctx.commit("setMerchant", response.data.data);
    }
  }
};
