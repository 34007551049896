import axiosIns from "@/libs/axios.js";

export default {
  state: {
    cities: [],
    city: {}
  },
  getters: {
    getCities: (state) => state.cities,
    getCity: (state) => state.city
  },
  mutations: {
    setCities(state, cities) {
      state.cities = cities;
    },
    setCity(state, city) {
      state.city = city;
    }
  },
  actions: {
    async fetchCities(ctx) {
      const response = await axiosIns.get("v1/city/getAll", {
        params: {
          page: 1,
          size: 50
        }
      });

      ctx.commit("setCities", response.data.data);
    },
    async fetchCity(ctx, id) {
      const response = await axiosIns.get('v1/city/get-by-id', {
        params: {
          cityId: id
        }
      });
      ctx.commit("setCity", response.data.data);
    }
  }
};
