import i18n, { selectedLocale } from "@/libs/i18n";

export default {
  state: {
    locale: selectedLocale
  },
  getters: {
    getLocale: (state) => state.locale
  },
  mutations: {
    updateLocale(state, newLocale) {
      i18n.locale = newLocale;
      state.locale = newLocale; // а здесь меняем значение для переключателя // запоминаем текущий язык, что б после перезагрузки страницы не сбросило на стандартный
      localStorage.setItem("locale", newLocale);
    },
  },
};
