import axiosIns from "@/libs/axios.js";

export default {
  state: {
    product: [],
    productImages: [],
  },
  getters: {
    getProduct: (state) => state.product,
    getProductImages: (state) => state.productImages,
  },
  mutations: {
    setProduct(state, product) {
      state.product = product;
    },
    setProductImages(state, productImages) {
      const filteredProductImagesForSubmit = state.product.productImageList.map(
        function (item) {
          return item.url
        }
      );
      state.product.productImageList =
        filteredProductImagesForSubmit.concat(productImages);
    },
  },
  actions: {
    async fetchProduct(ctx, id) {
      const response = await axiosIns.get(`product/${id}`);

      const filteredProductImages = response.data.data.productImageList.map(
        function (item) {
          return {
            id: item.id,
            name: "",
            size: 0,
            type: "image",
            url: item.url,
            myurl: item.url,
          };
        }
      );

      response.data.data.filteredProductImages = filteredProductImages;
      ctx.commit("setProduct", response.data.data);
    },
  },
};
