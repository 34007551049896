export default [
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/others/users/users-list/UsersList.vue")
  },
  {
    path: "/user/:id",
    name: "user-view",
    component: () => import("@/views/others/users/user-view/UserView.vue")
  },
  {
    path: "/user-edit/:id",
    name: "user-edit",
    component: () => import("@/views/others/users/user-edit/UserEdit.vue")
  },
  {
    path: "/categories",
    name: "categories",
    component: () => import("@/views/others/categories/categories-list/CategoriesList.vue")
  },
  {
    path: "/category-edit/:id",
    name: "category-edit",
    component: () => import("@/views/others/categories/category-edit/CategoryEdit.vue")
  },
  {
    path: "/merchants",
    name: "merchants",
    component: () => import("@/views/others/merchants/merchants-list/MerchantsList.vue")
  },
  {
    path: "/merchant/:id",
    name: "merchant-view",
    component: () => import("@/views/others/merchants/merchant-view/MerchantView.vue")
  },
  {
    path: "/merchant-edit/:id",
    name: "merchant-edit",
    component: () => import("@/views/others/merchants/merchant-edit/MerchantEdit.vue")
  },
  {
    path: "/branches",
    name: "branches",
    component: () => import("@/views/others/branches/branches-list/BranchesList.vue")
  },
  {
    path: "/branch-edit/:id",
    name: "branch-edit",
    component: () => import("@/views/others/branches/branch-edit/BranchEdit.vue")
  },
  {
    path: "/offers",
    name: "offers",
    component: () => import("@/views/others/offers/offers-list/OffersList.vue")
  },
  {
    path: "/offer-edit/:id",
    name: "offer-edit",
    component: () => import("@/views/others/offers/offer-edit/OfferEdit.vue")
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/others/products/products-list/ProductsList.vue")
  },
  {
    path: "/product-edit/:id",
    name: "product-edit",
    component: () => import("@/views/others/products/product-edit/ProductEdit.vue")
  },
  {
    path: "/cities",
    name: "cities",
    component: () => import("@/views/others/cities/cities-list/CitiesList.vue")
  },
  {
    path: "/city-edit/:id",
    name: "city-edit",
    component: () => import("@/views/others/cities/city-edit/CityEdit.vue")
  },
  {
    path: "/sliders",
    name: "sliders",
    component: () => import("@/views/others/sliders/sliders-list/SlidersList.vue")
  },
  {
    path: "/slider-edit/:id",
    name: "slider-edit",
    component: () => import("@/views/others/sliders/slider-edit/SliderEdit.vue")
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/others/settings/Settings.vue")
  },
]
