import axiosIns from "@/libs/axios.js";

export default {
  state: {
    branch: []
  },
  getters: {
    getBranch: (state) => state.branch
  },
  mutations: {
    setBranch(state, branch) {
      state.branch = branch;
    }
  },
  actions: {
    async fetchBranch(ctx, id) {
      const response = await axiosIns.get(`branches/get-by-id?branchId=${id}`);
      ctx.commit("setBranch", response.data.data);
    }
  }
};
