const url = "http://82.148.1.251:8088/api/v1"
export default {
  
  // Endpoints
  loginEndpoint: `${url}/auth/login/`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${url}/auth/refresh-token`,
  logoutEndpoint: `${url}/jwt/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: '',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
