import axiosIns from "@/libs/axios.js";

export default {
  state: {
    categories: [],
    category: {}
  },
  getters: {
    getCategories: (state) => state.categories,
    getCategory: (state) => state.category
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setCategory(state, category) {
      state.category = category;
    }
  },
  actions: {
    async fetchCategories(ctx) {
      const response = await axiosIns.get("vendor-category/getAll", {
        params: {
          page: 1,
          size: 50,
          field: 'isActive',
          fieldValue: 1
        }
      });

      ctx.commit("setCategories", response.data.data);
    },
    async fetchCategory(ctx, id) {
      const response = await axiosIns.get('vendor-category/get-by-id', {
        params: {
          categoryId: id
        }
      });
      ctx.commit("setCategory", response.data.data);
    }
  }
};
