import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import moment from "moment";
import VueMoment from "vue-moment";
import VueFileAgent from "vue-file-agent";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
import { SlickList, SlickItem } from "vue-slicksort";
import VueTheMask from "vue-the-mask";
/* import { regex } from "vee-validate/dist/rules";
extend("regex", regex); */



Vue.use(VueTheMask);
Vue.use(VueFileAgent);
Vue.use(VueMoment, {
  moment,
});
moment.locale("ru");

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component("vfa-sortable-list", SlickList);
Vue.component("vfa-sortable-item", SlickItem);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("D MMMM YYYY г.");
  }
});
Vue.filter("formatDateHours", function (value) {
  if (value) {
    return moment(String(value)).format("D MMMM YYYY г. HH:MM");
  }
});
